<template>
  <div class="page-auth">
    <Topbar />

    <div class="content-page">
      <div class="content">
        <div class="container-fluid">

          <div class="row align-items-center auth-box">
            <div class="col-12 col-sm-12 col-lg-6 m-auto">
              <slot />

            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Topbar from "@/components/Topbar";
import Footer from "@/components/Footer";

export default {
  components: {
    Topbar,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.auth-box {
  @media (min-width: 992px) {
    min-height: calc(100vh - 70px - 45px);
    margin: -40px -27px 0 -27px;
  }
}
</style>
