<template>
  <Auth>
    <div class="row justify-content-center my-5 my-lg-auto">
      <div class="col-sm-8 col-md-10 col-xl-8">
        <form action="#" @submit.prevent="handleSubmit">
          <h1 class="display-3 mt-0 mb-3">{{ $t('log-in') }}</h1>

          <div class="card">
            <div class="card-body p-3">
              <b-alert variant="danger" class="mt-3" dismissible :show="Boolean(error)">{{ error }}</b-alert>
              <div class="form-group mb-3">
                <label for="admin-login">{{ $t('form.login-email') }}</label>
                <input class="form-control" v-model="form.email" :placeholder="$t('form.login-email-placeholder')"
                  :class="{ 'is-invalid': submitted && $v.form.email.$error }" />
                <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                  <span v-if="!$v.form.email.required">{{ $t('form.login-email-req') }}</span>
                </div>
              </div>

              <div class="form-group mb-3">
                <label for="admin-password">{{ $t('form.password') }}</label>
                <div class="input-group input-group-merge">
                  <input v-model="form.password" :type="showPassword ? 'text' : 'password'" id="admin-password"
                    class="form-control" :placeholder="$t('form.password-placeholder')"
                    :class="{ 'is-invalid': submitted && $v.form.password.$error }" />

                  <div class="input-group-append" @click="showPassword = !showPassword" role="button">
                    <div class="input-group-text">
                      <span v-if="showPassword" class="material-symbols-rounded fz-18">visibility</span>
                      <span v-else class="material-symbols-rounded fz-18">visibility_off</span>
                    </div>
                  </div>
                  <div v-if="submitted && !$v.form.password.required" class="invalid-feedback">
                    {{ $t('form.password-req') }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <button class="btn btn-primary btn-block w-100" type="submit" :disabled="loading">
              {{ $t('log-in') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </Auth>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'
import Auth from "@/views/layouts/auth";
import { required } from "vuelidate/lib/validators";

export default {
  metaInfo() {
    return {
      title: this.$t('app.title') + " | " + this.$t('page.login'),
    };
  },
  components: {
    Auth,
  },
  data() {
    return {
      loading: false,
      error: "",
      form: {
        email: '',
        password: '',
      },
      showPassword: false,
      submitted: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
      },
      password: {
        required,
      },
    }
  },
  methods: {
    ...mapActions(useGlobalStore, ['login']),
    async handleSubmit() {
      this.submitted = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        this.error = "";

        const req = await this.login(this.form)
        // console.log("req: ", req)

        if (req.ok) {
          this.$router.push('/')
        } else {
          // console.log('login page, req: ', req)
          this.error = this.$t('msg.error')

          if (req.message && req.response?.data?.error) {
            this.error = req.message + ': ' + this.$t('msg.' + req.response.data.error)
          }
        }

        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
